import { AccountGroupId } from '@local/hash-graph-types/account';
import { AccountId } from '@local/hash-graph-types/account';
import { AggregatedUsageRecord } from '@local/hash-isomorphic-utils/service-usage';
import { AuthorizationSubjectId } from '@local/hash-graph-types/authorization';
import { BaseUrl } from '@local/hash-graph-types/ontology';
import { ClosedMultiEntityType } from '@local/hash-graph-types/ontology';
import { ClosedMultiEntityTypesDefinitions } from '@local/hash-graph-types/ontology';
import { ClosedMultiEntityTypesRootMap } from '@local/hash-graph-types/ontology';
import { ConstructEntityTypeParams } from '@local/hash-isomorphic-utils/types';
import { ConstructPropertyTypeParams } from '@local/hash-isomorphic-utils/types';
import { CountEntitiesParams } from '@local/hash-graph-client';
import { CreatedByIdsMap } from '@local/hash-graph-sdk/entity';
import { DataTypeConversionsMap } from '@local/hash-isomorphic-utils/data-types';
import { DiffEntityInput } from '@local/hash-subgraph';
import { DiffEntityResult } from '@local/hash-graph-client';
import { Edges } from '@local/hash-subgraph';
import { EntityId } from '@local/hash-graph-types/entity';
import { EntityMetadata } from '@local/hash-graph-types/entity';
import { EntityQueryCursor } from '@local/hash-graph-client';
import { EntityRecordId } from '@local/hash-graph-types/entity';
import { EntityRelationAndSubject } from '@local/hash-subgraph';
import { EntityTemporalVersioningMetadata } from '@local/hash-graph-types/entity';
import { EntityTypeWithMetadata } from '@local/hash-graph-types/ontology';
import { EntityUuid } from '@local/hash-graph-types/entity';
import { EntityValidationReport } from '@local/hash-graph-types/validation';
import { ExternalInputRequest } from '@local/hash-isomorphic-utils/flows/types';
import { ExternalInputResponseWithoutUser } from '@local/hash-isomorphic-utils/flows/types';
import { Filter } from '@local/hash-graph-client';
import { FlowDataSources } from '@local/hash-isomorphic-utils/flows/types';
import { FlowDefinition } from '@local/hash-isomorphic-utils/flows/types';
import { FlowInputs } from '@local/hash-isomorphic-utils/flows/types';
import { FlowTrigger } from '@local/hash-isomorphic-utils/flows/types';
import { GetEntitySubgraphRequest } from '@local/hash-isomorphic-utils/types';
import { GraphElementVertexId } from '@local/hash-subgraph';
import { HasIndexedContentProperties } from '@local/hash-isomorphic-utils/system-types/shared';
import { HasSpatiallyPositionedContentProperties } from '@local/hash-isomorphic-utils/system-types/canvas';
import { JsonObject } from '@blockprotocol/core';
import { LinkData } from '@local/hash-graph-types/entity';
import { OntologyTemporalMetadata } from '@local/hash-graph-client';
import { OwnedById } from '@local/hash-graph-types/web';
import { PropertyObject } from '@local/hash-graph-types/entity';
import { PropertyObjectWithMetadata } from '@local/hash-graph-types/entity';
import { PropertyPatchOperation } from '@local/hash-graph-types/entity';
import { PropertyTypeWithMetadata } from '@local/hash-graph-types/ontology';
import { ProspectiveUserProperties } from '@local/hash-isomorphic-utils/system-types/prospectiveuser';
import { QueryOperationInput } from '@blockprotocol/graph';
import { SerializedEntity } from '@local/hash-graph-sdk/entity';
import { SerializedVertices } from '@local/hash-subgraph';
import { StepInput } from '@local/hash-isomorphic-utils/flows/types';
import { StepProgressLog } from '@local/hash-isomorphic-utils/flows/types';
import { StepRunOutput } from '@local/hash-isomorphic-utils/flows/types';
import { SubgraphTemporalAxes } from '@local/hash-subgraph';
import { TextToken } from '@local/hash-isomorphic-utils/types';
import { TypeIdsMap } from '@local/hash-graph-sdk/entity';
import { TypeTitlesMap } from '@local/hash-graph-sdk/entity';
import { UserPermissions } from '@local/hash-isomorphic-utils/types';
import { UserPermissionsOnEntities } from '@local/hash-isomorphic-utils/types';
import { UserPermissionsOnEntityType } from '@local/hash-isomorphic-utils/types';
import { ValidateEntityParamsComponents } from '@local/hash-graph-client';
import { VersionedUrl } from '@blockprotocol/type-system';
import { WebIdsMap } from '@local/hash-graph-sdk/entity';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AccountGroupId: AccountGroupId;
  AccountId: AccountId;
  AggregatedUsageRecord: AggregatedUsageRecord;
  ArbitraryJsonData: any;
  AuthorizationSubjectId: AuthorizationSubjectId;
  BaseUrl: BaseUrl;
  ClosedMultiEntityType: ClosedMultiEntityType;
  ClosedMultiEntityTypesDefinitions: ClosedMultiEntityTypesDefinitions;
  ClosedMultiEntityTypesRootMap: ClosedMultiEntityTypesRootMap;
  ConstructEntityTypeParams: ConstructEntityTypeParams;
  ConstructPropertyTypeParams: ConstructPropertyTypeParams;
  CountEntitiesParams: CountEntitiesParams;
  CreatedByIdsMap: CreatedByIdsMap;
  DataTypeConversionsMap: DataTypeConversionsMap;
  Date: string;
  DiffEntityInput: DiffEntityInput;
  DiffEntityResult: DiffEntityResult;
  Edges: Edges;
  EntityId: EntityId;
  EntityMetadata: EntityMetadata;
  EntityQueryCursor: EntityQueryCursor;
  EntityRecordId: EntityRecordId;
  EntityRelationAndSubject: EntityRelationAndSubject;
  EntityTemporalVersioningMetadata: EntityTemporalVersioningMetadata;
  EntityTypeWithMetadata: EntityTypeWithMetadata;
  EntityUuid: EntityUuid;
  EntityValidationReport: EntityValidationReport;
  ExternalInputRequest: ExternalInputRequest;
  ExternalInputResponseWithoutUser: ExternalInputResponseWithoutUser;
  Filter: Filter;
  FlowDataSources: FlowDataSources;
  FlowDefinition: FlowDefinition;
  FlowInputs: FlowInputs;
  FlowTrigger: FlowTrigger;
  GetEntitySubgraphRequest: GetEntitySubgraphRequest;
  GraphElementVertexId: GraphElementVertexId;
  HasIndexedContentProperties: HasIndexedContentProperties;
  HasSpatiallyPositionedContentProperties: HasSpatiallyPositionedContentProperties;
  JSONObject: JsonObject;
  LinkData: LinkData;
  OntologyTemporalMetadata: OntologyTemporalMetadata;
  OwnedById: OwnedById;
  PropertyObject: PropertyObject;
  PropertyObjectWithMetadata: PropertyObjectWithMetadata;
  PropertyPatchOperation: PropertyPatchOperation;
  PropertyTypeWithMetadata: PropertyTypeWithMetadata;
  ProspectiveUserProperties: ProspectiveUserProperties;
  QueryOperationInput: QueryOperationInput;
  SerializedEntity: SerializedEntity;
  SerializedVertices: SerializedVertices;
  StepInput: StepInput;
  StepProgressLog: StepProgressLog;
  StepRunOutput: StepRunOutput;
  SubgraphTemporalAxes: SubgraphTemporalAxes;
  TextToken: TextToken;
  TypeIdsMap: TypeIdsMap;
  TypeTitlesMap: TypeTitlesMap;
  UserPermissions: UserPermissions;
  UserPermissionsOnEntities: UserPermissionsOnEntities;
  UserPermissionsOnEntityType: UserPermissionsOnEntityType;
  ValidateEntityParamsComponents: ValidateEntityParamsComponents;
  VersionedUrl: VersionedUrl;
  WebIdsMap: WebIdsMap;
};

export type AccountAuthorizationSubject = {
  accountId: Scalars['AccountId'];
};

export type AccountGroupAuthorizationSubject = {
  accountGroupId: Scalars['AccountGroupId'];
  relation?: Maybe<AccountGroupAuthorizationSubjectRelation>;
};

export enum AccountGroupAuthorizationSubjectRelation {
  Member = 'Member'
}

export enum AuthorizationSubjectKind {
  Account = 'Account',
  AccountGroup = 'AccountGroup',
  Public = 'Public'
}

export type AuthorizationViewerInput = {
  kind: AuthorizationSubjectKind;
  viewer?: InputMaybe<Scalars['AuthorizationSubjectId']>;
};

export type Block = {
  /** The block's linked child entity. */
  blockChildEntity: Scalars['SerializedEntity'];
  /** The component id of the block. */
  componentId: Scalars['String'];
  /** Metadata for the entity. */
  metadata: Scalars['EntityMetadata'];
  /** Properties of entity. */
  properties: Scalars['PropertyObject'];
};

export type BlockCollection = {
  /** The contents of the block collection. */
  contents: Array<BlockCollectionContentItem>;
  /** Metadata for the entity. */
  metadata: Scalars['EntityMetadata'];
  /** Properties of entity. */
  properties: Scalars['PropertyObject'];
};

/**
 * A special return type to include blocks linked from block collections and the link entity (as it might contain positioning data)
 * @todo – migrate from block collections having special return types to returning subgraphs like other entities
 */
export type BlockCollectionContentItem = {
  linkEntity: Scalars['SerializedEntity'];
  rightEntity: Block;
};

export type BlockProtocolBlock = {
  author: Scalars['String'];
  blockSitePath: Scalars['String'];
  blockType: BlockType;
  componentId: Scalars['String'];
  createdAt?: Maybe<Scalars['String']>;
  default?: Maybe<Scalars['JSONObject']>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  exampleGraph?: Maybe<Scalars['String']>;
  examples?: Maybe<Array<Scalars['JSONObject']>>;
  externals?: Maybe<Scalars['JSONObject']>;
  icon?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  lastUpdated?: Maybe<Scalars['String']>;
  license?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  npmPackageName?: Maybe<Scalars['String']>;
  pathWithNamespace: Scalars['String'];
  protocol?: Maybe<Scalars['String']>;
  repository?: Maybe<Scalars['String']>;
  schema?: Maybe<Scalars['String']>;
  source: Scalars['String'];
  variants?: Maybe<Array<BlockVariant>>;
  version: Scalars['String'];
};

export type BlockType = {
  entryPoint: Scalars['String'];
  tagName?: Maybe<Scalars['String']>;
};

export type BlockVariant = {
  description?: Maybe<Scalars['String']>;
  examples?: Maybe<Array<Scalars['JSONObject']>>;
  icon?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  properties?: Maybe<Scalars['JSONObject']>;
};

export type Comment = {
  /** User that created the comment */
  author: Scalars['SerializedEntity'];
  /** Whether or not the requesting user can edit this comment */
  canUserEdit: Scalars['Boolean'];
  /** Stringified timestamp of when the entity was deleted. */
  deletedAt?: Maybe<Scalars['String']>;
  /** Text contents of the comment */
  hasText: Array<Scalars['TextToken']>;
  /** Metadata for the entity. */
  metadata: Scalars['EntityMetadata'];
  /** Parent entity the comment belongs to */
  parent: Scalars['SerializedEntity'];
  /** Properties of entity. */
  properties: Scalars['PropertyObject'];
  /** Array of comments created in response to this comment */
  replies: Array<Comment>;
  /** Stringified timestamp of when the entity was resolved. */
  resolvedAt?: Maybe<Scalars['String']>;
  /** Version information of when the comment was last edited */
  textUpdatedAt: Scalars['EntityTemporalVersioningMetadata'];
};

/** Create an entity, which you can then reference in other actions, such as a InsertBlockAction */
export type CreateEntityAction = {
  entity: EntityDefinition;
  entityPlaceholderId?: InputMaybe<Scalars['ID']>;
  ownedById: Scalars['OwnedById'];
};

export type EdgeResolveDepths = {
  incoming: Scalars['Int'];
  outgoing: Scalars['Int'];
};

export type EdgeResolveDepthsInput = {
  incoming: Scalars['Int'];
  outgoing: Scalars['Int'];
};

export type Embed = {
  height?: Maybe<Scalars['Int']>;
  html: Scalars['String'];
  providerName: Scalars['String'];
  width: Scalars['Int'];
};

export enum EntityAuthorizationRelation {
  Editor = 'Editor',
  Owner = 'Owner',
  Viewer = 'Viewer'
}

export type EntityAuthorizationRelationship = {
  objectEntityId: Scalars['EntityId'];
  relation: EntityAuthorizationRelation;
  subject: EntityAuthorizationSubject;
};

export type EntityAuthorizationSubject = AccountAuthorizationSubject | AccountGroupAuthorizationSubject | PublicAuthorizationSubject;

export type EntityDefinition = {
  /** The properties of the new entity. */
  entityProperties?: InputMaybe<Scalars['PropertyObjectWithMetadata']>;
  /** The type(s) of the new entity. */
  entityTypeIds?: InputMaybe<Array<Scalars['VersionedUrl']>>;
  /**
   * The EntityId of the existing entity to use instead of creating a new entity.
   * This may be a reference to a placeholder set using placeholderId on a previous UpdatePageContentsAction.
   */
  existingEntityId?: InputMaybe<Scalars['EntityId']>;
  /** Associated Entities to either create/get and link to this entity. */
  linkedEntities?: InputMaybe<Array<LinkedEntityDefinition>>;
};

export type EntityDiff = {
  diff: Scalars['DiffEntityResult'];
  input: Scalars['DiffEntityInput'];
};

export type EntityTypeUpdate = {
  entityTypeId: Scalars['VersionedUrl'];
  updatedEntityType: Scalars['ConstructEntityTypeParams'];
};

export type EntityUpdateDefinition = {
  /** Whether the updated entity should be a draft */
  draft?: InputMaybe<Scalars['Boolean']>;
  /** The id of the entity. */
  entityId: Scalars['EntityId'];
  /** The new type(s) of the updated entity */
  entityTypeIds?: InputMaybe<Array<Scalars['VersionedUrl']>>;
  /** The patch operations to apply to the entity's properties */
  propertyPatches: Array<Scalars['PropertyPatchOperation']>;
};

export type FileEntityCreationInput = {
  /** Optionally provide a more specific type for the file entity, which must inherit from @hash's File system type */
  entityTypeId?: InputMaybe<Scalars['VersionedUrl']>;
  /** The owner for the created file entity. */
  ownedById: Scalars['OwnedById'];
};

export type FileEntityUpdateInput = {
  /** Optionally provide a more specific type for the file entity, which must inherit from @hash's File system type */
  entityTypeId?: InputMaybe<Scalars['VersionedUrl']>;
  /** The entityId of the existing file entity, if this is replacing an existing file */
  existingFileEntityId: Scalars['EntityId'];
};

export type FlowRun = {
  /** When the run stopped */
  closedAt?: Maybe<Scalars['String']>;
  /** When the run began executing (which may be after it was started if it has a delay before execution) */
  executedAt?: Maybe<Scalars['String']>;
  /** The reason the run failed, if it did */
  failureMessage?: Maybe<Scalars['String']>;
  /** The id for the definition of the flow this run is executing (the template for the flow) */
  flowDefinitionId: Scalars['String'];
  /**
   * The uuid of the flow run
   * This corresponds to:
   * - the EntityUuid of the Flow entity
   * - the workflowId of the Temporal workflow, which is unique among all currently-executing Temporal workflow executions
   *
   * There may be multiple runs with the same workflowId if a flow is 'continued as new' (see Temporal docs), OR fails and is retried.
   * – the same workflowId/flowRunId is the mechanism by which consecutive runs which continue from/retry a previous can be identified.
   *
   * While Temporal allows for re-use of workflowId across arbitrary flows, our business logic does not re-use them, and they are only re-used:
   * 1. in the 'continue as new' case, in which case we will need to combine the history of those runs to form a complete picture of the flow's execution.
   * 2. in the retry case, in which case the failed runs are only important if we want to expose past failures to the user.
   */
  flowRunId: Scalars['EntityUuid'];
  /** Any requests for external input made by steps within the Flow */
  inputRequests: Array<Scalars['ExternalInputRequest']>;
  /** Inputs to the flow run */
  inputs: Scalars['FlowInputs'];
  /**
   * A user-facing name for the flow run to distinguish it from other runs of its kind,
   * which might include a key input or a summary of its key inputs.
   */
  name: Scalars['String'];
  /** Outputs of the flow run */
  outputs?: Maybe<Array<Scalars['StepRunOutput']>>;
  /** When the run was triggered */
  startedAt: Scalars['String'];
  /** Details of the run's status, inputs, outputs etc */
  status: FlowRunStatus;
  /** The steps in the flow */
  steps: Array<StepRun>;
  /** The web this flow run is associated with */
  webId: Scalars['OwnedById'];
};

export enum FlowRunStatus {
  /** Successfully handled a cancellation request */
  Cancelled = 'CANCELLED',
  /** Completed successfully */
  Completed = 'COMPLETED',
  /**
   * The run was closed in favour of spawning a new run with the same parameters and a fresh event history
   * See https://docs.temporal.io/workflows#continue-as-new
   */
  ContinuedAsNew = 'CONTINUED_AS_NEW',
  /** Returned an error and failed */
  Failed = 'FAILED',
  /** Actively progressing or waiting on something */
  Running = 'RUNNING',
  /** Was terminated */
  Terminated = 'TERMINATED',
  /** Reached a timeout limit */
  TimedOut = 'TIMED_OUT',
  Unknown = 'UNKNOWN',
  Unspecified = 'UNSPECIFIED'
}

export enum FlowStepStatus {
  Cancelled = 'CANCELLED',
  CancelRequested = 'CANCEL_REQUESTED',
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  InformationRequired = 'INFORMATION_REQUIRED',
  Scheduled = 'SCHEDULED',
  Started = 'STARTED',
  TimedOut = 'TIMED_OUT'
}

export type GetClosedMultiEntityTypeResponse = {
  closedMultiEntityType: Scalars['ClosedMultiEntityType'];
  definitions: Scalars['ClosedMultiEntityTypesDefinitions'];
};

export type GetEntitySubgraphResponse = {
  closedMultiEntityTypes?: Maybe<Scalars['ClosedMultiEntityTypesRootMap']>;
  count?: Maybe<Scalars['Int']>;
  createdByIds?: Maybe<Scalars['CreatedByIdsMap']>;
  cursor?: Maybe<Scalars['EntityQueryCursor']>;
  definitions?: Maybe<Scalars['ClosedMultiEntityTypesDefinitions']>;
  editionCreatedByIds?: Maybe<Scalars['CreatedByIdsMap']>;
  subgraph: Subgraph;
  typeIds?: Maybe<Scalars['TypeIdsMap']>;
  typeTitles?: Maybe<Scalars['TypeTitlesMap']>;
  userPermissionsOnEntities: Scalars['UserPermissionsOnEntities'];
  webIds?: Maybe<Scalars['WebIdsMap']>;
};

export type HashInstanceSettings = {
  entity: Scalars['SerializedEntity'];
  isUserAdmin: Scalars['Boolean'];
};

/** Insert a block into a block collection with a corresponding entity. */
export type InsertBlockAction = {
  /** Allows UpdateBlockCollectionContentsActions to reference entities created in other actions. Also allows callers to updateBlockCollectionContents to find the entity id created for this definition in the result. See UpdateBlockCollectionContentsResult. */
  blockPlaceholderId?: InputMaybe<Scalars['ID']>;
  /** The block componentId. */
  componentId?: InputMaybe<Scalars['ID']>;
  /** The entity to associate with the new block */
  entity: EntityDefinition;
  /** Allows UpdateBlockCollectionContentsActions to reference entities created in other actions. Also allows callers to updateBlockCollectionContents to find the entity id created for this definition in the result. See UpdateBlockCollectionContentsResult. */
  entityPlaceholderId?: InputMaybe<Scalars['ID']>;
  /**
   * The block entity to insert into the block collection. You should not set a componentId
   * if you provide this
   */
  existingBlockEntityId?: InputMaybe<Scalars['EntityId']>;
  /** The account ID to create the block and associated entity in. */
  ownedById: Scalars['OwnedById'];
  /** The position of the block in the block collection */
  position: PositionInput;
};

export type IsGenerationAvailableResponse = {
  available: Scalars['Boolean'];
  reason?: Maybe<Scalars['String']>;
};

export type LinearOrganization = {
  /** The unique identifier of the entity. */
  id: Scalars['ID'];
  /** The organization's logo URL. */
  logoUrl?: Maybe<Scalars['String']>;
  /** The organization's name. */
  name: Scalars['String'];
  /** Teams associated with the organization. */
  teams: Array<LinearTeam>;
};

export type LinearTeam = {
  /** The team's color. */
  color?: Maybe<Scalars['String']>;
  /** The team's description. */
  description?: Maybe<Scalars['String']>;
  /** The icon of the team. */
  icon?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** The team's name. */
  name: Scalars['String'];
  /** Whether the team is private or not. */
  private: Scalars['Boolean'];
};

export type LinkedEntityDefinition = {
  destinationAccountId: Scalars['AccountId'];
  entity: EntityDefinition;
  /** The index of the link (if any) */
  index?: InputMaybe<Scalars['Int']>;
  linkEntityTypeId: Scalars['VersionedUrl'];
};

/** Move a block within a block collection. */
export type MoveBlockAction = {
  /** The EntityId of the link between the block collection and the block */
  linkEntityId: Scalars['EntityId'];
  /** The position of the block in the block collection */
  position: PositionInput;
};

export type Mutation = {
  addAccountGroupMember: Scalars['Boolean'];
  addEntityEditor: Scalars['Boolean'];
  addEntityOwner: Scalars['Boolean'];
  addEntityViewer: Scalars['Boolean'];
  /**
   * Archive multiple entities. If archiving any entity fails, any successfully archived
   * entities will be un-archived.
   */
  archiveEntities: Scalars['Boolean'];
  /** Archive an entity. */
  archiveEntity: Scalars['Boolean'];
  /** Archive a entity type. */
  archiveEntityType: Scalars['OntologyTemporalMetadata'];
  /** Archive a property type. */
  archivePropertyType: Scalars['OntologyTemporalMetadata'];
  /**
   * Cancel a flow, stopping its execution.
   *
   * This does _NOT_ roll back any database mutations made as part of the flow so far.
   */
  cancelFlow: Scalars['Boolean'];
  /** Create a new comment */
  createComment: Comment;
  /** Create an entity. */
  createEntity: Scalars['SerializedEntity'];
  /** Create a entity type. */
  createEntityType: Scalars['EntityTypeWithMetadata'];
  /**
   * Creates a file entity from an external link. The file entity will just have
   * a reference to the link (the file isn't fetched by our server in this current version)
   */
  createFileFromUrl: Scalars['SerializedEntity'];
  /** Create an organization. The creator will be automatically added as an org member. */
  createOrg: Subgraph;
  /** Create a new page */
  createPage: Page;
  /** Create a property type. */
  createPropertyType: Scalars['PropertyTypeWithMetadata'];
  /** Delete an existing comment */
  deleteComment: Comment;
  removeAccountGroupMember: Scalars['Boolean'];
  removeEntityEditor: Scalars['Boolean'];
  removeEntityOwner: Scalars['Boolean'];
  removeEntityViewer: Scalars['Boolean'];
  /**
   * Requests to upload a file, returning the url and data needed
   * for a client to POST a file to afterwards
   */
  requestFileUpload: RequestFileUploadResponse;
  /**
   * Reset a flow to a specific checkpoint, available via the 'checkpoints' field on a run
   *
   * This will archive any claims discovered after the checkpoint.
   * It does _NOT_ yet handle any other database mutations, e.g. entities persisted.
   *
   * Any API usage incurred at any point will still be recorded, whether or not it is after the checkpoint.
   */
  resetFlow: Scalars['Boolean'];
  /** Resolve an existing comment */
  resolveComment: Comment;
  /**
   * Set the parent of a page
   *
   * If the parentPageEntityId is not set, any existing page link is removed.
   */
  setParentPage: Page;
  /** Start a new flow run, and return its flowRunId to allow for identifying it later. */
  startFlow: Scalars['EntityUuid'];
  submitEarlyAccessForm?: Maybe<Scalars['Boolean']>;
  /** Submit a response to a request from a flow step for external input */
  submitExternalInputResponse: Scalars['Boolean'];
  /** Sync linear integration with HASH workspaces (users or orgs) */
  syncLinearIntegrationWithWorkspaces?: Maybe<Scalars['SerializedEntity']>;
  /** Unarchive a entity type. */
  unarchiveEntityType: Scalars['OntologyTemporalMetadata'];
  /** Unarchive a property type. */
  unarchivePropertyType: Scalars['OntologyTemporalMetadata'];
  /** Update the contents of a block collection. */
  updateBlockCollectionContents: UpdateBlockCollectionContentsResult;
  /** Edit an existing comment's text contents */
  updateCommentText: Comment;
  /** Update multiple entities. */
  updateEntities: Scalars['SerializedEntity'];
  /** Update an entity. */
  updateEntity: Scalars['SerializedEntity'];
  /** Update a entity type. */
  updateEntityType: Scalars['EntityTypeWithMetadata'];
  /** Update multiple entity types at once. */
  updateEntityTypes: Array<Scalars['EntityTypeWithMetadata']>;
  /** Update an existing page. */
  updatePage: Page;
  /** Update a property type. */
  updatePropertyType: Scalars['PropertyTypeWithMetadata'];
};


export type MutationAddAccountGroupMemberArgs = {
  accountGroupId: Scalars['AccountGroupId'];
  accountId: Scalars['AccountId'];
};


export type MutationAddEntityEditorArgs = {
  editor: Scalars['AuthorizationSubjectId'];
  entityId: Scalars['EntityId'];
};


export type MutationAddEntityOwnerArgs = {
  entityId: Scalars['EntityId'];
  owner: Scalars['AuthorizationSubjectId'];
};


export type MutationAddEntityViewerArgs = {
  entityId: Scalars['EntityId'];
  viewer: AuthorizationViewerInput;
};


export type MutationArchiveEntitiesArgs = {
  entityIds: Array<Scalars['EntityId']>;
};


export type MutationArchiveEntityArgs = {
  entityId: Scalars['EntityId'];
};


export type MutationArchiveEntityTypeArgs = {
  entityTypeId: Scalars['VersionedUrl'];
};


export type MutationArchivePropertyTypeArgs = {
  propertyTypeId: Scalars['VersionedUrl'];
};


export type MutationCancelFlowArgs = {
  flowUuid: Scalars['ID'];
};


export type MutationCreateCommentArgs = {
  parentEntityId: Scalars['EntityId'];
  textualContent: Array<Scalars['TextToken']>;
};


export type MutationCreateEntityArgs = {
  draft?: InputMaybe<Scalars['Boolean']>;
  entityTypeIds: Array<Scalars['VersionedUrl']>;
  linkData?: InputMaybe<Scalars['LinkData']>;
  linkedEntities?: InputMaybe<Array<LinkedEntityDefinition>>;
  ownedById?: InputMaybe<Scalars['OwnedById']>;
  properties: Scalars['PropertyObjectWithMetadata'];
  relationships?: InputMaybe<Array<Scalars['EntityRelationAndSubject']>>;
};


export type MutationCreateEntityTypeArgs = {
  entityType: Scalars['ConstructEntityTypeParams'];
  ownedById?: InputMaybe<Scalars['OwnedById']>;
};


export type MutationCreateFileFromUrlArgs = {
  description?: InputMaybe<Scalars['String']>;
  displayName?: InputMaybe<Scalars['String']>;
  fileEntityCreationInput?: InputMaybe<FileEntityCreationInput>;
  fileEntityUpdateInput?: InputMaybe<FileEntityUpdateInput>;
  url: Scalars['String'];
};


export type MutationCreateOrgArgs = {
  hasLeftEntity?: EdgeResolveDepthsInput;
  hasRightEntity?: EdgeResolveDepthsInput;
  name: Scalars['String'];
  shortname: Scalars['String'];
  websiteUrl?: InputMaybe<Scalars['String']>;
};


export type MutationCreatePageArgs = {
  ownedById: Scalars['OwnedById'];
  properties: PageCreationData;
};


export type MutationCreatePropertyTypeArgs = {
  ownedById?: InputMaybe<Scalars['OwnedById']>;
  propertyType: Scalars['ConstructPropertyTypeParams'];
};


export type MutationDeleteCommentArgs = {
  entityId: Scalars['EntityId'];
};


export type MutationRemoveAccountGroupMemberArgs = {
  accountGroupId: Scalars['AccountGroupId'];
  accountId: Scalars['AccountId'];
};


export type MutationRemoveEntityEditorArgs = {
  editor: Scalars['AuthorizationSubjectId'];
  entityId: Scalars['EntityId'];
};


export type MutationRemoveEntityOwnerArgs = {
  entityId: Scalars['EntityId'];
  owner: Scalars['AuthorizationSubjectId'];
};


export type MutationRemoveEntityViewerArgs = {
  entityId: Scalars['EntityId'];
  viewer: AuthorizationViewerInput;
};


export type MutationRequestFileUploadArgs = {
  description?: InputMaybe<Scalars['String']>;
  displayName?: InputMaybe<Scalars['String']>;
  fileEntityCreationInput?: InputMaybe<FileEntityCreationInput>;
  fileEntityUpdateInput?: InputMaybe<FileEntityUpdateInput>;
  name: Scalars['String'];
  size: Scalars['Int'];
};


export type MutationResetFlowArgs = {
  checkpointId: Scalars['ID'];
  eventId: Scalars['Int'];
  flowUuid: Scalars['ID'];
};


export type MutationResolveCommentArgs = {
  entityId: Scalars['EntityId'];
};


export type MutationSetParentPageArgs = {
  nextIndex?: InputMaybe<Scalars['String']>;
  pageEntityId: Scalars['EntityId'];
  parentPageEntityId?: InputMaybe<Scalars['EntityId']>;
  prevFractionalIndex?: InputMaybe<Scalars['String']>;
};


export type MutationStartFlowArgs = {
  dataSources: Scalars['FlowDataSources'];
  flowDefinition: Scalars['FlowDefinition'];
  flowTrigger: Scalars['FlowTrigger'];
  webId: Scalars['OwnedById'];
};


export type MutationSubmitEarlyAccessFormArgs = {
  properties: Scalars['ProspectiveUserProperties'];
};


export type MutationSubmitExternalInputResponseArgs = {
  flowUuid: Scalars['ID'];
  response: Scalars['ExternalInputResponseWithoutUser'];
};


export type MutationSyncLinearIntegrationWithWorkspacesArgs = {
  linearIntegrationEntityId: Scalars['EntityId'];
  syncWithWorkspaces: Array<SyncWithWorkspace>;
};


export type MutationUnarchiveEntityTypeArgs = {
  entityTypeId: Scalars['VersionedUrl'];
};


export type MutationUnarchivePropertyTypeArgs = {
  propertyTypeId: Scalars['VersionedUrl'];
};


export type MutationUpdateBlockCollectionContentsArgs = {
  actions: Array<UpdateBlockCollectionAction>;
  entityId: Scalars['EntityId'];
};


export type MutationUpdateCommentTextArgs = {
  entityId: Scalars['EntityId'];
  textualContent: Array<Scalars['TextToken']>;
};


export type MutationUpdateEntitiesArgs = {
  entityUpdates: Array<EntityUpdateDefinition>;
};


export type MutationUpdateEntityArgs = {
  entityUpdate: EntityUpdateDefinition;
};


export type MutationUpdateEntityTypeArgs = {
  entityTypeId: Scalars['VersionedUrl'];
  updatedEntityType: Scalars['ConstructEntityTypeParams'];
};


export type MutationUpdateEntityTypesArgs = {
  updates: Array<EntityTypeUpdate>;
};


export type MutationUpdatePageArgs = {
  entityId: Scalars['EntityId'];
  updatedProperties: PageUpdateData;
};


export type MutationUpdatePropertyTypeArgs = {
  propertyTypeId: Scalars['VersionedUrl'];
  updatedPropertyType: Scalars['ConstructPropertyTypeParams'];
};

export type OutgoingEdgeResolveDepth = {
  outgoing: Scalars['Int'];
};

export type OutgoingEdgeResolveDepthInput = {
  outgoing: Scalars['Int'];
};

export type Page = {
  /** Whether or not this page has been archived. */
  archived?: Maybe<Scalars['Boolean']>;
  /** The contents of the page. */
  contents: Array<BlockCollectionContentItem>;
  /** The fractional index of the page in the page tree. */
  fractionalIndex?: Maybe<Scalars['String']>;
  /** The icon given to the page. */
  icon?: Maybe<Scalars['String']>;
  /** Metadata for the entity. */
  metadata: Scalars['EntityMetadata'];
  /** Properties of entity. */
  properties: Scalars['PropertyObject'];
  /** A summary of the page. */
  summary?: Maybe<Scalars['String']>;
  /** The title of the page. */
  title: Scalars['String'];
  /** The permissions the requesting user has on the page */
  userPermissions: Scalars['UserPermissions'];
};

export type PageCreationData = {
  /** The fractional index of the page that is before the current in the account page tree. */
  prevFractionalIndex?: InputMaybe<Scalars['String']>;
  /** The page title. */
  title: Scalars['String'];
  /** The type of page */
  type: PageType;
};

export enum PageType {
  Canvas = 'canvas',
  Document = 'document'
}

export type PageUpdateData = {
  archived?: InputMaybe<Scalars['Boolean']>;
  fractionalIndex?: InputMaybe<Scalars['String']>;
  icon?: InputMaybe<Scalars['String']>;
  summary?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type PositionInput =
  /** Spatial positioning data for blocks in a canvas view */
  { canvasPosition: Scalars['HasSpatiallyPositionedContentProperties']; indexPosition?: never; }
  |  /** Indexed positioning of the block among other blocks in an indexed block collection */
  { canvasPosition?: never; indexPosition: Scalars['HasIndexedContentProperties']; };

/** Presigned data to send a PUT request to upload a file */
export type PresignedPut = {
  /** url to POST the file to */
  url: Scalars['String'];
};

export type PublicAuthorizationSubject = {
  public: Scalars['Boolean'];
};

export type Query = {
  /** Get a specified list of blocks by their entity id */
  blocks: Array<Block>;
  checkUserPermissionsOnEntity: Scalars['UserPermissions'];
  /** Check the requesting user's permissions on an entity type */
  checkUserPermissionsOnEntityType: Scalars['UserPermissionsOnEntityType'];
  countEntities: Scalars['Int'];
  /** Accepts a url and returns embeddable html for it, and the provider name */
  embedCode: Embed;
  /** Generate the inverse form of a relationship (e.g. Parent Of -> Child Of, Employee Of -> Employer Of) */
  generateInverse: Scalars['String'];
  /** Generates the plural form of a word or phrase (e.g. Company -> Companies) */
  generatePlural: Scalars['String'];
  getBlockProtocolBlocks: Array<BlockProtocolBlock>;
  getClosedMultiEntityType: GetClosedMultiEntityTypeResponse;
  /** Get a subgraph rooted at an data type resolved by its versioned URL. */
  getDataType: Subgraph;
  getDataTypeConversionTargets: Scalars['DataTypeConversionsMap'];
  /** Get a subgraph rooted at an entity resolved by its id. */
  getEntity: SubgraphAndPermissions;
  getEntityAuthorizationRelationships: Array<EntityAuthorizationRelationship>;
  getEntityDiffs: Array<EntityDiff>;
  getEntitySubgraph: GetEntitySubgraphResponse;
  /** Get a subgraph rooted at an entity type resolved by its versioned URL. */
  getEntityType: Subgraph;
  getFlowRunById: FlowRun;
  getFlowRuns: Array<FlowRun>;
  /** Get the linear organization */
  getLinearOrganization: LinearOrganization;
  /** Get a subgraph rooted at an property type resolved by its versioned URL. */
  getPropertyType: Subgraph;
  getUsageRecords: Array<UserUsageRecords>;
  /** Get the user's position on the access waitlist (for hosted HASH) */
  getWaitlistPosition: Scalars['Int'];
  /** Determines whether the authenticated user has access to the instance of HASH */
  hasAccessToHash: Scalars['Boolean'];
  /** Get the HASH instance settings */
  hashInstanceSettings?: Maybe<HashInstanceSettings>;
  isEntityPublic: Scalars['Boolean'];
  /** Check whether the generation resolvers are available */
  isGenerationAvailable: IsGenerationAvailableResponse;
  /** Determines whether a provided shortname is already taken */
  isShortnameTaken: Scalars['Boolean'];
  me: SubgraphAndPermissions;
  pageComments: Array<Comment>;
  /** Get a subgraph rooted at all data types that match a given filter. */
  queryDataTypes: Subgraph;
  /** Implementation of the Block Protocol queryEntities hook */
  queryEntities: SubgraphAndPermissions;
  /** Get a subgraph rooted at all entity types that match a given filter. */
  queryEntityTypes: Subgraph;
  /** Get a subgraph rooted at all property types that match a given filter. */
  queryPropertyTypes: Subgraph;
  /**
   * Validates the requested aspects of an entity
   *
   * Throws an error if the entity is invalid, with an object containing the invalid properties.
   *
   * Returns 'true' if the entity is valid
   */
  validateEntity?: Maybe<Scalars['EntityValidationReport']>;
};


export type QueryBlocksArgs = {
  blocks: Array<Scalars['EntityId']>;
};


export type QueryCheckUserPermissionsOnEntityArgs = {
  metadata: Scalars['EntityMetadata'];
};


export type QueryCheckUserPermissionsOnEntityTypeArgs = {
  entityTypeId: Scalars['VersionedUrl'];
};


export type QueryCountEntitiesArgs = {
  request: Scalars['CountEntitiesParams'];
};


export type QueryEmbedCodeArgs = {
  type?: InputMaybe<Scalars['String']>;
  url: Scalars['String'];
};


export type QueryGenerateInverseArgs = {
  relationship: Scalars['String'];
};


export type QueryGeneratePluralArgs = {
  singular: Scalars['String'];
};


export type QueryGetClosedMultiEntityTypeArgs = {
  entityTypeIds: Array<Scalars['VersionedUrl']>;
  includeArchived?: InputMaybe<Scalars['Boolean']>;
  includeDrafts?: InputMaybe<Scalars['Boolean']>;
};


export type QueryGetDataTypeArgs = {
  constrainsValuesOn: OutgoingEdgeResolveDepthInput;
  dataTypeId: Scalars['VersionedUrl'];
  includeArchived?: InputMaybe<Scalars['Boolean']>;
};


export type QueryGetDataTypeConversionTargetsArgs = {
  dataTypeIds: Array<Scalars['VersionedUrl']>;
};


export type QueryGetEntityArgs = {
  constrainsLinkDestinationsOn: OutgoingEdgeResolveDepthInput;
  constrainsLinksOn: OutgoingEdgeResolveDepthInput;
  constrainsPropertiesOn: OutgoingEdgeResolveDepthInput;
  constrainsValuesOn: OutgoingEdgeResolveDepthInput;
  entityId: Scalars['EntityId'];
  entityVersion?: InputMaybe<Scalars['String']>;
  hasLeftEntity: EdgeResolveDepthsInput;
  hasRightEntity: EdgeResolveDepthsInput;
  includeDrafts?: InputMaybe<Scalars['Boolean']>;
  inheritsFrom: OutgoingEdgeResolveDepthInput;
  isOfType: OutgoingEdgeResolveDepthInput;
};


export type QueryGetEntityAuthorizationRelationshipsArgs = {
  entityId: Scalars['EntityId'];
};


export type QueryGetEntityDiffsArgs = {
  inputs: Array<Scalars['DiffEntityInput']>;
};


export type QueryGetEntitySubgraphArgs = {
  request: Scalars['GetEntitySubgraphRequest'];
};


export type QueryGetEntityTypeArgs = {
  constrainsLinkDestinationsOn: OutgoingEdgeResolveDepthInput;
  constrainsLinksOn: OutgoingEdgeResolveDepthInput;
  constrainsPropertiesOn: OutgoingEdgeResolveDepthInput;
  constrainsValuesOn: OutgoingEdgeResolveDepthInput;
  entityTypeId: Scalars['VersionedUrl'];
  includeArchived?: InputMaybe<Scalars['Boolean']>;
  inheritsFrom: OutgoingEdgeResolveDepthInput;
};


export type QueryGetFlowRunByIdArgs = {
  flowRunId: Scalars['String'];
};


export type QueryGetFlowRunsArgs = {
  executionStatus?: InputMaybe<FlowRunStatus>;
  flowDefinitionIds?: InputMaybe<Array<Scalars['String']>>;
};


export type QueryGetLinearOrganizationArgs = {
  linearOrgId: Scalars['ID'];
};


export type QueryGetPropertyTypeArgs = {
  constrainsPropertiesOn: OutgoingEdgeResolveDepthInput;
  constrainsValuesOn: OutgoingEdgeResolveDepthInput;
  includeArchived?: InputMaybe<Scalars['Boolean']>;
  propertyTypeId: Scalars['VersionedUrl'];
};


export type QueryIsEntityPublicArgs = {
  entityId: Scalars['EntityId'];
};


export type QueryIsShortnameTakenArgs = {
  shortname: Scalars['String'];
};


export type QueryMeArgs = {
  hasLeftEntity?: EdgeResolveDepthsInput;
  hasRightEntity?: EdgeResolveDepthsInput;
};


export type QueryPageCommentsArgs = {
  entityId: Scalars['EntityId'];
};


export type QueryQueryDataTypesArgs = {
  constrainsValuesOn: OutgoingEdgeResolveDepthInput;
  includeArchived?: InputMaybe<Scalars['Boolean']>;
};


export type QueryQueryEntitiesArgs = {
  constrainsLinkDestinationsOn: OutgoingEdgeResolveDepthInput;
  constrainsLinksOn: OutgoingEdgeResolveDepthInput;
  constrainsPropertiesOn: OutgoingEdgeResolveDepthInput;
  constrainsValuesOn: OutgoingEdgeResolveDepthInput;
  hasLeftEntity: EdgeResolveDepthsInput;
  hasRightEntity: EdgeResolveDepthsInput;
  includeDrafts?: InputMaybe<Scalars['Boolean']>;
  inheritsFrom: OutgoingEdgeResolveDepthInput;
  isOfType: OutgoingEdgeResolveDepthInput;
  operation: Scalars['QueryOperationInput'];
};


export type QueryQueryEntityTypesArgs = {
  constrainsLinkDestinationsOn: OutgoingEdgeResolveDepthInput;
  constrainsLinksOn: OutgoingEdgeResolveDepthInput;
  constrainsPropertiesOn: OutgoingEdgeResolveDepthInput;
  constrainsValuesOn: OutgoingEdgeResolveDepthInput;
  filter?: InputMaybe<Scalars['Filter']>;
  includeArchived?: InputMaybe<Scalars['Boolean']>;
  inheritsFrom: OutgoingEdgeResolveDepthInput;
  latestOnly?: InputMaybe<Scalars['Boolean']>;
};


export type QueryQueryPropertyTypesArgs = {
  constrainsPropertiesOn: OutgoingEdgeResolveDepthInput;
  constrainsValuesOn: OutgoingEdgeResolveDepthInput;
  includeArchived?: InputMaybe<Scalars['Boolean']>;
  latestOnly?: InputMaybe<Scalars['Boolean']>;
};


export type QueryValidateEntityArgs = {
  components: Scalars['ValidateEntityParamsComponents'];
  entityTypes: Array<Scalars['VersionedUrl']>;
  properties: Scalars['PropertyObjectWithMetadata'];
};

/** Remove a block from a block collection. */
export type RemoveBlockAction = {
  /** The EntityId of the link between the block collection and the block */
  linkEntityId: Scalars['EntityId'];
};

export type RequestFileUploadResponse = {
  /** The file Entity */
  entity: Scalars['SerializedEntity'];
  /** Presigned object containing the info needed to send a PUT request */
  presignedPut: PresignedPut;
};

export type ResolveDepths = {
  constrainsLinkDestinationsOn: OutgoingEdgeResolveDepth;
  constrainsLinksOn: OutgoingEdgeResolveDepth;
  constrainsPropertiesOn: OutgoingEdgeResolveDepth;
  constrainsValuesOn: OutgoingEdgeResolveDepth;
  hasLeftEntity: EdgeResolveDepths;
  hasRightEntity: EdgeResolveDepths;
  inheritsFrom: OutgoingEdgeResolveDepth;
  isOfType: OutgoingEdgeResolveDepth;
};

export type StepRun = {
  /** Starting at 1, the number of times execution of this step has been attempted. */
  attempt: Scalars['Int'];
  /**
   * When the step last execution attempt finished, either successfully or unsuccessfully.
   * Another execution may yet be scheduled – check retryState.
   */
  closedAt?: Maybe<Scalars['String']>;
  /** Inputs to the step */
  inputs?: Maybe<Array<Scalars['StepInput']>>;
  /**
   * Details of the last failure, if any.
   * The step may still be running if it has been retried since the last failure.
   */
  lastFailure?: Maybe<Scalars['ArbitraryJsonData']>;
  /** Logs from the step, reporting on progress as it executes */
  logs: Array<Scalars['StepProgressLog']>;
  /** Outputs of the step */
  outputs?: Maybe<Array<Scalars['StepRunOutput']>>;
  /** If the last execution failed, what retry policy applies. */
  retryState?: Maybe<Scalars['String']>;
  /**
   * When the step was LAST scheduled for execution - this may have happened previously if a previous execution failed.
   * See https://docs.temporal.io/activities#activity-execution for execution details
   */
  scheduledAt: Scalars['String'];
  /**
   * When execution of a step was LAST started - there may be earlier executions which failed.
   * Note that this event is not written to the history until execution completes (successfully or unsuccessfully)
   */
  startedAt?: Maybe<Scalars['String']>;
  /** The status of the step */
  status: FlowStepStatus;
  /**
   * id for the step
   * @todo do we also want nodeId to link it to the Step Definition, where there might be multiple steps per node definition
   *       in the case of spawning multiple parallel executions of a particular action?
   */
  stepId: Scalars['String'];
  /**
   * The type of step, i.e a name for what action it is performing, e.g. Persist Entities
   * @todo do we need this? it can be derived from the step definition.
   * @todo is this actually stepName, and we want a separate stepType of Action, Trigger etc?
   */
  stepType: Scalars['String'];
};

export type Subgraph = {
  depths: ResolveDepths;
  edges: Scalars['Edges'];
  roots: Array<Scalars['GraphElementVertexId']>;
  temporalAxes: Scalars['SubgraphTemporalAxes'];
  vertices: Scalars['SerializedVertices'];
};

export type SubgraphAndPermissions = {
  subgraph: Subgraph;
  userPermissionsOnEntities: Scalars['UserPermissionsOnEntities'];
};

/** Swap a blocks data */
export type SwapBlockDataAction = {
  /** The Block entity's fixed ID */
  entityId: Scalars['EntityId'];
  /** The new entity's fixed ID */
  newEntityEntityId: Scalars['EntityId'];
};

export type SyncWithWorkspace = {
  /** The linear team IDs to sync with the workspace */
  linearTeamIds: Array<Scalars['ID']>;
  /** The entity ID of the workspace (user or org) */
  workspaceEntityId: Scalars['EntityId'];
};

/**
 * An action to perform when updating the contents of a block collection. Exactly one field must be
 * specified.
 *
 * Note: a union type would be preferrable here, but currently, GraphQL does not
 * permit unions as input to a mutation
 */
export type UpdateBlockCollectionAction = {
  createEntity?: InputMaybe<CreateEntityAction>;
  insertBlock?: InputMaybe<InsertBlockAction>;
  moveBlock?: InputMaybe<MoveBlockAction>;
  removeBlock?: InputMaybe<RemoveBlockAction>;
  swapBlockData?: InputMaybe<SwapBlockDataAction>;
  updateEntity?: InputMaybe<UpdateEntityAction>;
};

export type UpdateBlockCollectionContentsResult = {
  blockCollection: BlockCollection;
  placeholders: Array<UpdateBlockCollectionContentsResultPlaceholder>;
};

/** Map of placeholder IDs used in the UpdateBlockCollectionContentsActions to the entity IDs created for those placeholders */
export type UpdateBlockCollectionContentsResultPlaceholder = {
  entityId: Scalars['EntityId'];
  placeholderId: Scalars['ID'];
};

/** Update an entity in a block collection. */
export type UpdateEntityAction = {
  /** The entity's fixed ID. */
  entityId: Scalars['EntityId'];
  /** The entity's new properties. */
  properties: Scalars['PropertyObject'];
};

export type UserUsageRecords = {
  shortname: Scalars['String'];
  usageRecords: Array<Scalars['AggregatedUsageRecord']>;
};

export type AddAccountGroupMemberMutationVariables = Exact<{
  accountId: Scalars['AccountId'];
  accountGroupId: Scalars['AccountGroupId'];
}>;


export type AddAccountGroupMemberMutation = { addAccountGroupMember: boolean };

export type RemoveAccountGroupMemberMutationVariables = Exact<{
  accountId: Scalars['AccountId'];
  accountGroupId: Scalars['AccountGroupId'];
}>;


export type RemoveAccountGroupMemberMutation = { removeAccountGroupMember: boolean };

export type GetBlockProtocolBlocksQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBlockProtocolBlocksQuery = { getBlockProtocolBlocks: Array<{ default?: JsonObject | null, description?: string | null, displayName?: string | null, examples?: Array<JsonObject> | null, externals?: JsonObject | null, icon?: string | null, image?: string | null, license?: string | null, name: string, protocol?: string | null, source: string, version: string, author: string, createdAt?: string | null, blockSitePath: string, componentId: string, exampleGraph?: string | null, lastUpdated?: string | null, npmPackageName?: string | null, pathWithNamespace: string, repository?: string | null, schema?: string | null, blockType: { entryPoint: string, tagName?: string | null }, variants?: Array<{ description?: string | null, examples?: Array<JsonObject> | null, icon?: string | null, name?: string | null, properties?: JsonObject | null }> | null }> };

export type CommentFieldsFragment = { canUserEdit: boolean, hasText: Array<TextToken>, textUpdatedAt: EntityTemporalVersioningMetadata, author: SerializedEntity, parent: SerializedEntity, metadata: EntityMetadata };

export type CreateCommentMutationVariables = Exact<{
  parentEntityId: Scalars['EntityId'];
  textualContent: Array<Scalars['TextToken']> | Scalars['TextToken'];
}>;


export type CreateCommentMutation = { createComment: { metadata: EntityMetadata } };

export type ResolveCommentMutationVariables = Exact<{
  entityId: Scalars['EntityId'];
}>;


export type ResolveCommentMutation = { resolveComment: { canUserEdit: boolean, hasText: Array<TextToken>, textUpdatedAt: EntityTemporalVersioningMetadata, author: SerializedEntity, parent: SerializedEntity, metadata: EntityMetadata } };

export type DeleteCommentMutationVariables = Exact<{
  entityId: Scalars['EntityId'];
}>;


export type DeleteCommentMutation = { deleteComment: { canUserEdit: boolean, hasText: Array<TextToken>, textUpdatedAt: EntityTemporalVersioningMetadata, author: SerializedEntity, parent: SerializedEntity, metadata: EntityMetadata } };

export type UpdateCommentTextMutationVariables = Exact<{
  entityId: Scalars['EntityId'];
  textualContent: Array<Scalars['TextToken']> | Scalars['TextToken'];
}>;


export type UpdateCommentTextMutation = { updateCommentText: { canUserEdit: boolean, hasText: Array<TextToken>, textUpdatedAt: EntityTemporalVersioningMetadata, author: SerializedEntity, parent: SerializedEntity, metadata: EntityMetadata } };

export type GenerateInverseQueryVariables = Exact<{
  relationship: Scalars['String'];
}>;


export type GenerateInverseQuery = { generateInverse: string };

export type GeneratePluralQueryVariables = Exact<{
  singular: Scalars['String'];
}>;


export type GeneratePluralQuery = { generatePlural: string };

export type IsGenerationAvailableQueryVariables = Exact<{ [key: string]: never; }>;


export type IsGenerationAvailableQuery = { isGenerationAvailable: { available: boolean, reason?: string | null } };

export type GetLinearOrganizationQueryVariables = Exact<{
  linearOrgId: Scalars['ID'];
}>;


export type GetLinearOrganizationQuery = { getLinearOrganization: { id: string, logoUrl?: string | null, name: string, teams: Array<{ id: string, name: string, description?: string | null, color?: string | null, icon?: string | null, private: boolean }> } };

export type SyncLinearIntegrationWithWorkspacesMutationVariables = Exact<{
  linearIntegrationEntityId: Scalars['EntityId'];
  syncWithWorkspaces: Array<SyncWithWorkspace> | SyncWithWorkspace;
}>;


export type SyncLinearIntegrationWithWorkspacesMutation = { syncLinearIntegrationWithWorkspaces?: SerializedEntity | null };

export type CreateEntityMutationVariables = Exact<{
  entityTypeIds: Array<Scalars['VersionedUrl']> | Scalars['VersionedUrl'];
  ownedById?: InputMaybe<Scalars['OwnedById']>;
  properties: Scalars['PropertyObjectWithMetadata'];
  linkData?: InputMaybe<Scalars['LinkData']>;
  draft?: InputMaybe<Scalars['Boolean']>;
  relationships?: InputMaybe<Array<Scalars['EntityRelationAndSubject']> | Scalars['EntityRelationAndSubject']>;
}>;


export type CreateEntityMutation = { createEntity: SerializedEntity };

export type QueryEntitiesQueryVariables = Exact<{
  operation: Scalars['QueryOperationInput'];
  constrainsValuesOn: OutgoingEdgeResolveDepthInput;
  constrainsPropertiesOn: OutgoingEdgeResolveDepthInput;
  constrainsLinksOn: OutgoingEdgeResolveDepthInput;
  constrainsLinkDestinationsOn: OutgoingEdgeResolveDepthInput;
  includePermissions: Scalars['Boolean'];
  inheritsFrom: OutgoingEdgeResolveDepthInput;
  isOfType: OutgoingEdgeResolveDepthInput;
  hasLeftEntity: EdgeResolveDepthsInput;
  hasRightEntity: EdgeResolveDepthsInput;
}>;


export type QueryEntitiesQuery = { queryEntities: { userPermissionsOnEntities?: UserPermissionsOnEntities, subgraph: { roots: Array<GraphElementVertexId>, vertices: SerializedVertices, edges: Edges, temporalAxes: SubgraphTemporalAxes, depths: { constrainsLinkDestinationsOn: { outgoing: number }, constrainsLinksOn: { outgoing: number }, constrainsValuesOn: { outgoing: number }, constrainsPropertiesOn: { outgoing: number }, inheritsFrom: { outgoing: number }, isOfType: { outgoing: number }, hasLeftEntity: { incoming: number, outgoing: number }, hasRightEntity: { incoming: number, outgoing: number } } } } };

export type GetEntitySubgraphQueryVariables = Exact<{
  request: Scalars['GetEntitySubgraphRequest'];
  includePermissions: Scalars['Boolean'];
}>;


export type GetEntitySubgraphQuery = { getEntitySubgraph: { closedMultiEntityTypes?: ClosedMultiEntityTypesRootMap | null, count?: number | null, cursor?: EntityQueryCursor | null, createdByIds?: CreatedByIdsMap | null, editionCreatedByIds?: CreatedByIdsMap | null, definitions?: ClosedMultiEntityTypesDefinitions | null, userPermissionsOnEntities?: UserPermissionsOnEntities, typeIds?: TypeIdsMap | null, typeTitles?: TypeTitlesMap | null, webIds?: WebIdsMap | null, subgraph: { roots: Array<GraphElementVertexId>, vertices: SerializedVertices, edges: Edges, temporalAxes: SubgraphTemporalAxes, depths: { constrainsLinkDestinationsOn: { outgoing: number }, constrainsLinksOn: { outgoing: number }, constrainsValuesOn: { outgoing: number }, constrainsPropertiesOn: { outgoing: number }, inheritsFrom: { outgoing: number }, isOfType: { outgoing: number }, hasLeftEntity: { incoming: number, outgoing: number }, hasRightEntity: { incoming: number, outgoing: number } } } } };

export type CountEntitiesQueryVariables = Exact<{
  request: Scalars['CountEntitiesParams'];
}>;


export type CountEntitiesQuery = { countEntities: number };

export type UpdateEntityMutationVariables = Exact<{
  entityUpdate: EntityUpdateDefinition;
}>;


export type UpdateEntityMutation = { updateEntity: SerializedEntity };

export type UpdateEntitiesMutationVariables = Exact<{
  entityUpdates: Array<EntityUpdateDefinition> | EntityUpdateDefinition;
}>;


export type UpdateEntitiesMutation = { updateEntities: SerializedEntity };

export type ArchiveEntityMutationVariables = Exact<{
  entityId: Scalars['EntityId'];
}>;


export type ArchiveEntityMutation = { archiveEntity: boolean };

export type ArchiveEntitiesMutationVariables = Exact<{
  entityIds: Array<Scalars['EntityId']> | Scalars['EntityId'];
}>;


export type ArchiveEntitiesMutation = { archiveEntities: boolean };

export type AddEntityOwnerMutationVariables = Exact<{
  entityId: Scalars['EntityId'];
  owner: Scalars['AuthorizationSubjectId'];
}>;


export type AddEntityOwnerMutation = { addEntityOwner: boolean };

export type RemoveEntityOwnerMutationVariables = Exact<{
  entityId: Scalars['EntityId'];
  owner: Scalars['AuthorizationSubjectId'];
}>;


export type RemoveEntityOwnerMutation = { removeEntityOwner: boolean };

export type AddEntityEditorMutationVariables = Exact<{
  entityId: Scalars['EntityId'];
  editor: Scalars['AuthorizationSubjectId'];
}>;


export type AddEntityEditorMutation = { addEntityEditor: boolean };

export type RemoveEntityEditorMutationVariables = Exact<{
  entityId: Scalars['EntityId'];
  editor: Scalars['AuthorizationSubjectId'];
}>;


export type RemoveEntityEditorMutation = { removeEntityEditor: boolean };

export type AddEntityViewerMutationVariables = Exact<{
  entityId: Scalars['EntityId'];
  viewer: AuthorizationViewerInput;
}>;


export type AddEntityViewerMutation = { addEntityViewer: boolean };

export type RemoveEntityViewerMutationVariables = Exact<{
  entityId: Scalars['EntityId'];
  viewer: AuthorizationViewerInput;
}>;


export type RemoveEntityViewerMutation = { removeEntityViewer: boolean };

export type IsEntityPublicQueryVariables = Exact<{
  entityId: Scalars['EntityId'];
}>;


export type IsEntityPublicQuery = { isEntityPublic: boolean };

export type GetEntityAuthorizationRelationshipsQueryVariables = Exact<{
  entityId: Scalars['EntityId'];
}>;


export type GetEntityAuthorizationRelationshipsQuery = { getEntityAuthorizationRelationships: Array<{ objectEntityId: EntityId, relation: EntityAuthorizationRelation, subject: { __typename: 'AccountAuthorizationSubject', accountId: AccountId } | { __typename: 'AccountGroupAuthorizationSubject', accountGroupId: AccountGroupId } | { __typename: 'PublicAuthorizationSubject', public: boolean } }> };

export type GetEntityDiffsQueryVariables = Exact<{
  inputs: Array<Scalars['DiffEntityInput']> | Scalars['DiffEntityInput'];
}>;


export type GetEntityDiffsQuery = { getEntityDiffs: Array<{ input: DiffEntityInput, diff: DiffEntityResult }> };

export type ValidateEntityQueryVariables = Exact<{
  components: Scalars['ValidateEntityParamsComponents'];
  entityTypes: Array<Scalars['VersionedUrl']> | Scalars['VersionedUrl'];
  properties: Scalars['PropertyObjectWithMetadata'];
}>;


export type ValidateEntityQuery = { validateEntity?: EntityValidationReport | null };

export type RequestFileUploadMutationVariables = Exact<{
  size: Scalars['Int'];
  name: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  displayName?: InputMaybe<Scalars['String']>;
  fileEntityCreationInput?: InputMaybe<FileEntityCreationInput>;
  fileEntityUpdateInput?: InputMaybe<FileEntityUpdateInput>;
}>;


export type RequestFileUploadMutation = { requestFileUpload: { entity: SerializedEntity, presignedPut: { url: string } } };

export type CreateFileFromUrlMutationVariables = Exact<{
  url: Scalars['String'];
  displayName?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  fileEntityCreationInput?: InputMaybe<FileEntityCreationInput>;
  fileEntityUpdateInput?: InputMaybe<FileEntityUpdateInput>;
}>;


export type CreateFileFromUrlMutation = { createFileFromUrl: SerializedEntity };

export type StartFlowMutationVariables = Exact<{
  dataSources: Scalars['FlowDataSources'];
  flowTrigger: Scalars['FlowTrigger'];
  flowDefinition: Scalars['FlowDefinition'];
  webId: Scalars['OwnedById'];
}>;


export type StartFlowMutation = { startFlow: EntityUuid };

export type ResetFlowMutationVariables = Exact<{
  flowUuid: Scalars['ID'];
  checkpointId: Scalars['ID'];
  eventId: Scalars['Int'];
}>;


export type ResetFlowMutation = { resetFlow: boolean };

export type CancelFlowMutationVariables = Exact<{
  flowUuid: Scalars['ID'];
}>;


export type CancelFlowMutation = { cancelFlow: boolean };

export type GetHashInstanceSettingsQueryQueryVariables = Exact<{ [key: string]: never; }>;


export type GetHashInstanceSettingsQueryQuery = { hashInstanceSettings?: { entity: SerializedEntity, isUserAdmin: boolean } | null };

export type CreateOrgMutationVariables = Exact<{
  shortname: Scalars['String'];
  name: Scalars['String'];
  websiteUrl?: InputMaybe<Scalars['String']>;
}>;


export type CreateOrgMutation = { createOrg: { roots: Array<GraphElementVertexId>, vertices: SerializedVertices, edges: Edges, temporalAxes: SubgraphTemporalAxes, depths: { constrainsLinkDestinationsOn: { outgoing: number }, constrainsLinksOn: { outgoing: number }, constrainsValuesOn: { outgoing: number }, constrainsPropertiesOn: { outgoing: number }, inheritsFrom: { outgoing: number }, isOfType: { outgoing: number }, hasLeftEntity: { incoming: number, outgoing: number }, hasRightEntity: { incoming: number, outgoing: number } } } };

export type GetDataTypeQueryVariables = Exact<{
  dataTypeId: Scalars['VersionedUrl'];
  constrainsValuesOn: OutgoingEdgeResolveDepthInput;
  includeArchived?: InputMaybe<Scalars['Boolean']>;
}>;


export type GetDataTypeQuery = { getDataType: { roots: Array<GraphElementVertexId>, vertices: SerializedVertices, edges: Edges, temporalAxes: SubgraphTemporalAxes, depths: { constrainsLinkDestinationsOn: { outgoing: number }, constrainsLinksOn: { outgoing: number }, constrainsValuesOn: { outgoing: number }, constrainsPropertiesOn: { outgoing: number }, inheritsFrom: { outgoing: number }, isOfType: { outgoing: number }, hasLeftEntity: { incoming: number, outgoing: number }, hasRightEntity: { incoming: number, outgoing: number } } } };

export type QueryDataTypesQueryVariables = Exact<{
  constrainsValuesOn: OutgoingEdgeResolveDepthInput;
  includeArchived?: InputMaybe<Scalars['Boolean']>;
}>;


export type QueryDataTypesQuery = { queryDataTypes: { roots: Array<GraphElementVertexId>, vertices: SerializedVertices, edges: Edges, temporalAxes: SubgraphTemporalAxes, depths: { constrainsLinkDestinationsOn: { outgoing: number }, constrainsLinksOn: { outgoing: number }, constrainsValuesOn: { outgoing: number }, constrainsPropertiesOn: { outgoing: number }, inheritsFrom: { outgoing: number }, isOfType: { outgoing: number }, hasLeftEntity: { incoming: number, outgoing: number }, hasRightEntity: { incoming: number, outgoing: number } } } };

export type GetDataTypeConversionTargetsQueryVariables = Exact<{
  dataTypeIds: Array<Scalars['VersionedUrl']> | Scalars['VersionedUrl'];
}>;


export type GetDataTypeConversionTargetsQuery = { getDataTypeConversionTargets: DataTypeConversionsMap };

export type GetEntityTypeQueryVariables = Exact<{
  entityTypeId: Scalars['VersionedUrl'];
  constrainsValuesOn: OutgoingEdgeResolveDepthInput;
  constrainsPropertiesOn: OutgoingEdgeResolveDepthInput;
  constrainsLinksOn: OutgoingEdgeResolveDepthInput;
  constrainsLinkDestinationsOn: OutgoingEdgeResolveDepthInput;
  inheritsFrom: OutgoingEdgeResolveDepthInput;
  includeArchived?: InputMaybe<Scalars['Boolean']>;
}>;


export type GetEntityTypeQuery = { getEntityType: { roots: Array<GraphElementVertexId>, vertices: SerializedVertices, edges: Edges, temporalAxes: SubgraphTemporalAxes, depths: { constrainsLinkDestinationsOn: { outgoing: number }, constrainsLinksOn: { outgoing: number }, constrainsValuesOn: { outgoing: number }, constrainsPropertiesOn: { outgoing: number }, inheritsFrom: { outgoing: number }, isOfType: { outgoing: number }, hasLeftEntity: { incoming: number, outgoing: number }, hasRightEntity: { incoming: number, outgoing: number } } } };

export type QueryEntityTypesQueryVariables = Exact<{
  constrainsValuesOn: OutgoingEdgeResolveDepthInput;
  constrainsPropertiesOn: OutgoingEdgeResolveDepthInput;
  constrainsLinksOn: OutgoingEdgeResolveDepthInput;
  constrainsLinkDestinationsOn: OutgoingEdgeResolveDepthInput;
  filter?: InputMaybe<Scalars['Filter']>;
  inheritsFrom: OutgoingEdgeResolveDepthInput;
  latestOnly?: InputMaybe<Scalars['Boolean']>;
  includeArchived?: InputMaybe<Scalars['Boolean']>;
}>;


export type QueryEntityTypesQuery = { queryEntityTypes: { roots: Array<GraphElementVertexId>, vertices: SerializedVertices, edges: Edges, temporalAxes: SubgraphTemporalAxes, depths: { constrainsLinkDestinationsOn: { outgoing: number }, constrainsLinksOn: { outgoing: number }, constrainsValuesOn: { outgoing: number }, constrainsPropertiesOn: { outgoing: number }, inheritsFrom: { outgoing: number }, isOfType: { outgoing: number }, hasLeftEntity: { incoming: number, outgoing: number }, hasRightEntity: { incoming: number, outgoing: number } } } };

export type GetClosedMultiEntityTypeQueryVariables = Exact<{
  entityTypeIds: Array<Scalars['VersionedUrl']> | Scalars['VersionedUrl'];
  includeArchived?: InputMaybe<Scalars['Boolean']>;
  includeDrafts?: InputMaybe<Scalars['Boolean']>;
}>;


export type GetClosedMultiEntityTypeQuery = { getClosedMultiEntityType: { closedMultiEntityType: ClosedMultiEntityType, definitions: ClosedMultiEntityTypesDefinitions } };

export type CreateEntityTypeMutationVariables = Exact<{
  ownedById: Scalars['OwnedById'];
  entityType: Scalars['ConstructEntityTypeParams'];
}>;


export type CreateEntityTypeMutation = { createEntityType: EntityTypeWithMetadata };

export type UpdateEntityTypeMutationVariables = Exact<{
  entityTypeId: Scalars['VersionedUrl'];
  updatedEntityType: Scalars['ConstructEntityTypeParams'];
}>;


export type UpdateEntityTypeMutation = { updateEntityType: EntityTypeWithMetadata };

export type UpdateEntityTypesMutationVariables = Exact<{
  updates: Array<EntityTypeUpdate> | EntityTypeUpdate;
}>;


export type UpdateEntityTypesMutation = { updateEntityTypes: Array<EntityTypeWithMetadata> };

export type ArchiveEntityTypeMutationVariables = Exact<{
  entityTypeId: Scalars['VersionedUrl'];
}>;


export type ArchiveEntityTypeMutation = { archiveEntityType: OntologyTemporalMetadata };

export type UnarchiveEntityTypeMutationVariables = Exact<{
  entityTypeId: Scalars['VersionedUrl'];
}>;


export type UnarchiveEntityTypeMutation = { unarchiveEntityType: OntologyTemporalMetadata };

export type CheckUserPermissionsOnEntityTypeQueryVariables = Exact<{
  entityTypeId: Scalars['VersionedUrl'];
}>;


export type CheckUserPermissionsOnEntityTypeQuery = { checkUserPermissionsOnEntityType: UserPermissionsOnEntityType };

export type GetPropertyTypeQueryVariables = Exact<{
  propertyTypeId: Scalars['VersionedUrl'];
  constrainsValuesOn: OutgoingEdgeResolveDepthInput;
  constrainsPropertiesOn: OutgoingEdgeResolveDepthInput;
  includeArchived?: InputMaybe<Scalars['Boolean']>;
}>;


export type GetPropertyTypeQuery = { getPropertyType: { roots: Array<GraphElementVertexId>, vertices: SerializedVertices, edges: Edges, temporalAxes: SubgraphTemporalAxes, depths: { constrainsLinkDestinationsOn: { outgoing: number }, constrainsLinksOn: { outgoing: number }, constrainsValuesOn: { outgoing: number }, constrainsPropertiesOn: { outgoing: number }, inheritsFrom: { outgoing: number }, isOfType: { outgoing: number }, hasLeftEntity: { incoming: number, outgoing: number }, hasRightEntity: { incoming: number, outgoing: number } } } };

export type QueryPropertyTypesQueryVariables = Exact<{
  constrainsValuesOn: OutgoingEdgeResolveDepthInput;
  constrainsPropertiesOn: OutgoingEdgeResolveDepthInput;
  latestOnly?: InputMaybe<Scalars['Boolean']>;
  includeArchived?: InputMaybe<Scalars['Boolean']>;
}>;


export type QueryPropertyTypesQuery = { queryPropertyTypes: { roots: Array<GraphElementVertexId>, vertices: SerializedVertices, edges: Edges, temporalAxes: SubgraphTemporalAxes, depths: { constrainsLinkDestinationsOn: { outgoing: number }, constrainsLinksOn: { outgoing: number }, constrainsValuesOn: { outgoing: number }, constrainsPropertiesOn: { outgoing: number }, inheritsFrom: { outgoing: number }, isOfType: { outgoing: number }, hasLeftEntity: { incoming: number, outgoing: number }, hasRightEntity: { incoming: number, outgoing: number } } } };

export type CreatePropertyTypeMutationVariables = Exact<{
  ownedById: Scalars['OwnedById'];
  propertyType: Scalars['ConstructPropertyTypeParams'];
}>;


export type CreatePropertyTypeMutation = { createPropertyType: PropertyTypeWithMetadata };

export type UpdatePropertyTypeMutationVariables = Exact<{
  propertyTypeId: Scalars['VersionedUrl'];
  updatedPropertyType: Scalars['ConstructPropertyTypeParams'];
}>;


export type UpdatePropertyTypeMutation = { updatePropertyType: PropertyTypeWithMetadata };

export type ArchivePropertyTypeMutationVariables = Exact<{
  propertyTypeId: Scalars['VersionedUrl'];
}>;


export type ArchivePropertyTypeMutation = { archivePropertyType: OntologyTemporalMetadata };

export type UnarchivePropertyTypeMutationVariables = Exact<{
  propertyTypeId: Scalars['VersionedUrl'];
}>;


export type UnarchivePropertyTypeMutation = { unarchivePropertyType: OntologyTemporalMetadata };

export type SetParentPageMutationVariables = Exact<{
  pageEntityId: Scalars['EntityId'];
  parentPageEntityId?: InputMaybe<Scalars['EntityId']>;
  prevFractionalIndex?: InputMaybe<Scalars['String']>;
  nextIndex?: InputMaybe<Scalars['String']>;
}>;


export type SetParentPageMutation = { setParentPage: { __typename: 'Page', metadata: EntityMetadata, title: string, summary?: string | null } };

export type CreatePageMutationVariables = Exact<{
  ownedById: Scalars['OwnedById'];
  properties: PageCreationData;
}>;


export type CreatePageMutation = { createPage: { metadata: EntityMetadata } };

export type UpdatePageMutationVariables = Exact<{
  entityId: Scalars['EntityId'];
  updatedProperties: PageUpdateData;
}>;


export type UpdatePageMutation = { updatePage: { metadata: EntityMetadata } };

export type GetPageCommentsQueryVariables = Exact<{
  entityId: Scalars['EntityId'];
}>;


export type GetPageCommentsQuery = { pageComments: Array<{ canUserEdit: boolean, hasText: Array<TextToken>, textUpdatedAt: EntityTemporalVersioningMetadata, author: SerializedEntity, parent: SerializedEntity, metadata: EntityMetadata, replies: Array<{ canUserEdit: boolean, hasText: Array<TextToken>, textUpdatedAt: EntityTemporalVersioningMetadata, author: SerializedEntity, parent: SerializedEntity, metadata: EntityMetadata }> }> };

export type IsShortnameTakenQueryVariables = Exact<{
  shortname: Scalars['String'];
}>;


export type IsShortnameTakenQuery = { isShortnameTaken: boolean };

export type HasAccessToHashQueryVariables = Exact<{ [key: string]: never; }>;


export type HasAccessToHashQuery = { hasAccessToHash: boolean };

export type GetWaitlistPositionQueryVariables = Exact<{ [key: string]: never; }>;


export type GetWaitlistPositionQuery = { getWaitlistPosition: number };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { me: { subgraph: { roots: Array<GraphElementVertexId>, vertices: SerializedVertices, edges: Edges, temporalAxes: SubgraphTemporalAxes, depths: { constrainsLinkDestinationsOn: { outgoing: number }, constrainsLinksOn: { outgoing: number }, constrainsValuesOn: { outgoing: number }, constrainsPropertiesOn: { outgoing: number }, inheritsFrom: { outgoing: number }, isOfType: { outgoing: number }, hasLeftEntity: { incoming: number, outgoing: number }, hasRightEntity: { incoming: number, outgoing: number } } } } };

export type SubmitEarlyAccessFormMutationVariables = Exact<{
  properties: Scalars['ProspectiveUserProperties'];
}>;


export type SubmitEarlyAccessFormMutation = { submitEarlyAccessForm?: boolean | null };

export type BlockFieldsFragment = { __typename: 'Block', metadata: EntityMetadata, properties: PropertyObject, blockChildEntity: SerializedEntity, componentId: string };

export type BlockCollectionFieldsFragment = { __typename: 'BlockCollection', metadata: EntityMetadata, properties: PropertyObject, contents: Array<{ linkEntity: SerializedEntity, rightEntity: { __typename: 'Block', metadata: EntityMetadata, properties: PropertyObject, blockChildEntity: SerializedEntity, componentId: string } }> };

export type UpdateBlockCollectionContentsMutationVariables = Exact<{
  entityId: Scalars['EntityId'];
  actions: Array<UpdateBlockCollectionAction> | UpdateBlockCollectionAction;
}>;


export type UpdateBlockCollectionContentsMutation = { updateBlockCollectionContents: { blockCollection: { __typename: 'BlockCollection', metadata: EntityMetadata, properties: PropertyObject, contents: Array<{ linkEntity: SerializedEntity, rightEntity: { __typename: 'Block', metadata: EntityMetadata, properties: PropertyObject, blockChildEntity: SerializedEntity, componentId: string } }> }, placeholders: Array<{ placeholderId: string, entityId: EntityId }> } };

export type GetEntityQueryVariables = Exact<{
  entityId: Scalars['EntityId'];
  entityVersion?: InputMaybe<Scalars['String']>;
  constrainsValuesOn: OutgoingEdgeResolveDepthInput;
  constrainsPropertiesOn: OutgoingEdgeResolveDepthInput;
  constrainsLinksOn: OutgoingEdgeResolveDepthInput;
  constrainsLinkDestinationsOn: OutgoingEdgeResolveDepthInput;
  includePermissions: Scalars['Boolean'];
  inheritsFrom: OutgoingEdgeResolveDepthInput;
  isOfType: OutgoingEdgeResolveDepthInput;
  hasLeftEntity: EdgeResolveDepthsInput;
  hasRightEntity: EdgeResolveDepthsInput;
  includeDrafts?: InputMaybe<Scalars['Boolean']>;
}>;


export type GetEntityQuery = { getEntity: { userPermissionsOnEntities?: UserPermissionsOnEntities, subgraph: { roots: Array<GraphElementVertexId>, vertices: SerializedVertices, edges: Edges, temporalAxes: SubgraphTemporalAxes, depths: { constrainsLinkDestinationsOn: { outgoing: number }, constrainsLinksOn: { outgoing: number }, constrainsValuesOn: { outgoing: number }, constrainsPropertiesOn: { outgoing: number }, inheritsFrom: { outgoing: number }, isOfType: { outgoing: number }, hasLeftEntity: { incoming: number, outgoing: number }, hasRightEntity: { incoming: number, outgoing: number } } } } };

export type CheckUserPermissionsOnEntityQueryVariables = Exact<{
  metadata: Scalars['EntityMetadata'];
}>;


export type CheckUserPermissionsOnEntityQuery = { checkUserPermissionsOnEntity: UserPermissions };

export type GetFlowRunsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetFlowRunsQuery = { getFlowRuns: Array<{ name: string, flowDefinitionId: string, flowRunId: EntityUuid, webId: OwnedById, status: FlowRunStatus, startedAt: string, executedAt?: string | null, closedAt?: string | null, inputRequests: Array<ExternalInputRequest>, steps: Array<{ scheduledAt: string, startedAt?: string | null, closedAt?: string | null, logs: Array<StepProgressLog> }> }> };

export type GetFlowRunByIdQueryVariables = Exact<{
  flowRunId: Scalars['String'];
}>;


export type GetFlowRunByIdQuery = { getFlowRunById: { name: string, flowDefinitionId: string, flowRunId: EntityUuid, failureMessage?: string | null, webId: OwnedById, status: FlowRunStatus, startedAt: string, executedAt?: string | null, closedAt?: string | null, inputRequests: Array<ExternalInputRequest>, inputs: FlowInputs, outputs?: Array<StepRunOutput> | null, steps: Array<{ stepId: string, stepType: string, status: FlowStepStatus, scheduledAt: string, startedAt?: string | null, closedAt?: string | null, attempt: number, lastFailure?: any | null, retryState?: string | null, inputs?: Array<StepInput> | null, outputs?: Array<StepRunOutput> | null, logs: Array<StepProgressLog> }> } };

export type SubmitExternalInputResponseMutationVariables = Exact<{
  flowUuid: Scalars['ID'];
  response: Scalars['ExternalInputResponseWithoutUser'];
}>;


export type SubmitExternalInputResponseMutation = { submitExternalInputResponse: boolean };

export type SubgraphFieldsFragment = { roots: Array<GraphElementVertexId>, vertices: SerializedVertices, edges: Edges, temporalAxes: SubgraphTemporalAxes, depths: { constrainsLinkDestinationsOn: { outgoing: number }, constrainsLinksOn: { outgoing: number }, constrainsValuesOn: { outgoing: number }, constrainsPropertiesOn: { outgoing: number }, inheritsFrom: { outgoing: number }, isOfType: { outgoing: number }, hasLeftEntity: { incoming: number, outgoing: number }, hasRightEntity: { incoming: number, outgoing: number } } };
